import React from 'react';
import GenericApplicationForm from '../../components/genericApplication';

const data = {
  id: 'hot-desking-application',
  seoTitle: 'Hot Desking',
  heading: 'Get a desk in our accelerator',
  description: 'Get a seat in our accelerator and enjoy the unique working atmosphere in the hottest startup area in Singapore.',
  formId: 'f5a3bd43-c539-4929-8cb9-db87818912dd',
};

const HotDeskingApplication = () => (
  <GenericApplicationForm data={data} />
);

export default HotDeskingApplication;
