import React from 'react';
import PropTypes from 'prop-types';
import HubspotForm from 'react-hubspot-form';
import SEO from './SEO';
import Layout from '../layouts/index';

const GenericApplicationPage = ({ data }) => (
  <Layout bodyClass={data.id}>
  <SEO title="{data.seoTitle}" />

    <div className="intro">
      <div className="container">
        <div className="row">
          <div className="col-12">
            <h1>{ data.heading }</h1>
            <p>
              { data.description }
            </p>
          </div>
        </div>
      </div>
    </div>
    <div className="container">
      <div className="row">
        <div className="col-12">
          <HubspotForm
            portalId="4364088"
            formId={data.formId}
            loading={<div>Loading...</div>}
          />
        </div>
      </div>
    </div>
  </Layout>
);

GenericApplicationPage.propTypes = {
  data: PropTypes.objectOf(PropTypes.object).isRequired,
};

export default GenericApplicationPage;
